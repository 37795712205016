import { MdPlayArrow } from "@react-icons/all-files/md/MdPlayArrow";
import React, { useContext, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "styled-components";
import CartContext from "../context/CartContext";

const AccordianDropdown = ({ val, items, valId, icon, width }) => {
	const { settingVal } = React.useContext(CartContext);
	const [turnArrow, setTurnArrow] = useState(false);
	const [eKey, setEKey] = useState("1");

	const ValueOnClick = (valNew) => {
		settingVal(valNew, valId);
		setTurnArrow(!turnArrow);
		setEKey("1");
	};
	return (
		<div
			className={`  py-0 ${width}`}
			style={{ border: "2px solid white", cursor: "pointer" }}
		>
			<Accordion activeKey={eKey}>
				<Card className="border-0 my-auto  py-0 my-0 bg-t">
					<Card.Header style={{ height: "66px" }} className="border-0 px-0 m-0">
						<ContextAwareToggle
							setEKey={setEKey}
							turnArrow={turnArrow}
							eKey={eKey}
							setTurnArrow={setTurnArrow}
							eventKey="0"
							className=" border-0 b-none"
							icon={icon}
						>
							<div className="d-flex align-items-center ">
								<p className="pb-0 mb-0 ssp-bold text-white fs-5">{val}</p>
							</div>
						</ContextAwareToggle>
					</Card.Header>
					<Accordion.Collapse
						style={{ backgroundColor: "white" }}
						eventKey="0"
						className=" whiteish"
					>
						{/* <Card.Body className="pt-0 mt-4"> */}
						<OptionWrapper className="whiteish">
							{/* {items.map((item) => {
                return <Option>{item}</Option>;
              })} */}

							{items.map((item, i, a) => (
								<Option>
									{a.length !== 1 ? (
										<span
											style={{ cursor: "pointer" }}
											onClick={() => ValueOnClick(item)}
										>
											{item}
										</span>
									) : (
										<>
											{item}
											<button
												className=" mb-0 pb-0 btn btn-primary"
												onClick={() =>
													ValueOnClick(item.props.children[1].props.value)
												}
											>
												Apply
											</button>
										</>
									)}
								</Option>
							))}
						</OptionWrapper>
						{/* </Card.Body> */}
					</Accordion.Collapse>
				</Card>
			</Accordion>
		</div>
	);
};

export default AccordianDropdown;

function ContextAwareToggle({
	children,
	eventKey,
	callback,
	setTurnArrow,
	turnArrow,
	setEKey,
	eKey,
	icon,
}) {
	const currentEventKey = useContext(AccordionContext);

	const decoratedOnClick = useAccordionButton(eventKey, () => {
		if (eKey === "1") {
			setEKey("0");
		} else if (eKey === "0") {
			setEKey("1");
		}
		callback && callback(eventKey);

		setTurnArrow(!turnArrow);
	});

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<Card.Header className="border-0" onClick={decoratedOnClick}>
			<Row className="align-items-center">
				<Col
					className="d-flex justify-content-center align-items-center ps-0"
					xs={2}
				>
					{/* <FaBuilding className="text-white fs-5" /> */}
					{icon}
				</Col>
				<Col
					className="d-flex justify-content-start align-items-center ps-0"
					xs={9}
				>
					{children}
				</Col>
				<Col xs={1} className="d-flex px-0  justify-content-start">
					<MdPlayArrow
						className={`position-relative fs-5 text-white  ${
							turnArrow ? "cheveron-up" : "cheveron-down "
						}`}
					/>
				</Col>
			</Row>
		</Card.Header>
	);
}

const OptionWrapper = styled.div`
	/* background: #00a8a8ad; */
	margin-top: 18px;
	border-radius: 10px;
	border: 1px #c9c9c9 solid;
	background: white !important;
	position: absolute;
	top: 100%;
	width: 90%;
	padding: 0px 10px 0px 10px;
	margin: 20px 10px 10px 10px;
	z-index: 2;
`;

const Option = styled.p`
	cursor: pointer;
	max-width: 100%;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	z-index: 2;
`;
