import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Carousel, Spinner } from "react-bootstrap";
import axios from "axios";

const Testimonials = () => {
	// const data = useStaticQuery(graphql`
	// 	query {
	// 		AssetLogo: wpMediaItem(title: { eq: "Flex-Icon-Navy" }) {
	// 			altText
	// 			localFile {
	// 				childImageSharp {
	// 					gatsbyImageData(
	// 						formats: [AUTO, WEBP]
	// 						quality: 100
	// 						transformOptions: { cropFocus: CENTER, fit: CONTAIN }
	// 						layout: CONSTRAINED
	// 						placeholder: BLURRED
	// 					)
	// 				}
	// 			}
	// 		}
	// 	}
	// `);

	const [reviews, setReviews] = useState([]);
	const num = 0;

	useEffect(() => {
		axios
			.get(
				`https://www.local-marketing-reports.com/external/showcase-reviews/widgets/5b051f6d635cc36d3e24d1d879030cfab3bec5e1`
			)
			.then((res) => {
				const reviewData = res.data.results;
				setReviews(reviewData);
				setReviews(reviewData);
				document.getElementById("loading-spinner").style.display = "none";
				document.getElementById("loading-spinner-small").style.display = "none";
			});
	}, [num]);

	const TESTIMONIALS = [
		{
			custName: "Damiaan van Zanen",
			position: "Managing Director",
			text: "Tony and Matt have assisted me and my team in three office moves now. We have been extremely satisfied with the outcome each time. They took time to really understand our needs as a team at that time, found several really great options for us to chose from and helped us right through the process. I highly recommend the Your Desk Workplace Solutions team if you are looking to make a change to your office space. These guys are experts in what they do and the results will speak for themselves!",
			date: "July 2022",
		},
		{
			custName: "Damiaan van Zanen",
			position: "Managing Director",
			text: "Tony and Matt have assisted me and my team in three office moves now. We have been extremely satisfied with the outcome each time. They took time to really understand our needs as a team at that time, found several really great options for us to chose from and helped us right through the process. I highly recommend the Your Desk Workplace Solutions team if you are looking to make a change to your office space. These guys are experts in what they do and the results will speak for themselves!",
			date: "July 2022",
		},
		{
			custName: "Damiaan van Zanen",
			position: "Managing Director",
			text: "Tony and Matt have assisted me and my team in three office moves now. We have been extremely satisfied with the outcome each time. They took time to really understand our needs as a team at that time, found several really great options for us to chose from and helped us right through the process. I highly recommend the Your Desk Workplace Solutions team if you are looking to make a change to your office space. These guys are experts in what they do and the results will speak for themselves!",
			date: "July 2022",
		},
	];

	return (
		<>
			<Container className="px-4 h-100 px-md-0">
				<Row className="pb-3 pb-lg-5">
					<Col className="text-center">
						<h2 className="fs-1">Customer Testimonials</h2>
					</Col>
				</Row>

				<Row className="g-6 pb-6 d-none h-100 d-lg-flex">
					<div
						id="loading-spinner"
						className={`w-100 position-relative`}
						style={{ height: "200px" }}
					>
						<span className="position-absolute bottom-0 start-50 translate-middle-x text-center pb-6 pb-lg">
							<Spinner animation="border" variant="primary" />
						</span>
					</div>
					{reviews
						.filter((item, index) => index < 3)
						.map((review) => {
							return (
								<Col xl={4} md={6} sm={12} style={{ minHeight: "100%" }}>
									<div
										className="rounded px-4 h-100 position-relative pt-5 pb-4"
										style={{ boxShadow: "0px 1px 20px #00000029" }}
									>
										<StaticImage
											src="../images/icons/google.svg"
											className="rounded-circle mx-auto ms-2 mb-3"
											style={{
												maxHeight: "35px",
												maxWidth: "35px",
											}}
											alt={"Google Logo"}
										/>
										<h4 className="pb-0 mb-0">{review.author}</h4>
										{/* <p>{test.position}</p> */}
										<hr />
										<p className="mb-5">{review.reviewBody}</p>
										{/* <div style={{ height: "30px" }} className="w-100"></div>
										<div className="position-absolute bottom-0 pb-4">
											<blockquote>
												<p className="fw-bold">
													{moment(review.datePublished).format("MMMM YYYY")}
												</p>
											</blockquote>
										</div> */}
									</div>
								</Col>
							);
						})}
				</Row>
			</Container>
			<div
				id="loading-spinner-small"
				className={`w-100 position-relative d-lg-none`}
				style={{ height: "200px" }}
			>
				<span className="position-absolute bottom-0 start-50 translate-middle-x text-center pb-6 pb-lg">
					<Spinner animation="border" variant="primary" />
				</span>
			</div>
			<Carousel variant="dark" className="d-lg-none mt-4 mb-4">
				{reviews
					.filter((item, index) => index < 3)
					.map((review) => {
						return (
							<Carousel.Item className="">
								<div
									className="rounded px-5 w-100 d-flex justify-content-center pt-2 pb-6"
									//   style={{ boxShadow: "0px 1px 20px #00000029" }}
								>
									<div className="w-100 w-md-70">
										<StaticImage
											src="../images/icons/google.svg"
											className="rounded-circle mx-auto ms-2 mb-3"
											style={{
												maxHeight: "35px",
												maxWidth: "35px",
											}}
											alt={"Google Logo"}
										/>
										<h4 className="pb-0 mb-0">{review.author}</h4>
										{/* <p>{test.position}</p> */}
										<hr />
										<p className="mb-3">{review.reviewBody}</p>
										{/* <blockquote>
											<p className="fw-bold">
												{moment(review.datePublished).format("MMMM YYYY")}
											</p>
										</blockquote> */}
									</div>
								</div>
								{/* <Carousel.Caption>
                  <h3 className="text-black">First slide label</h3>
                  <p>
                    Nulla vitae elit libero, a pharetra augue mollis interdum.
                  </p>
                </Carousel.Caption> */}
							</Carousel.Item>
						);
					})}
			</Carousel>
		</>
	);
};

const socialIcons = {
	color: "rgba(0, 0, 0, 0.5)",
};

const quickLinks = {};

export default Testimonials;
