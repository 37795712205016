import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import gsap from "gsap";
import React, { useEffect, useState } from "react";
import CartContext from "../context/CartContext";
import { FaPhone } from "react-icons/fa";
import { FiMail } from "react-icons/fi";

const TeamCard = ({ imageData, title, name, id }) => {
	const image = imageData?.localFile.childImageSharp.gatsbyImageData;

	const imageAlt = imageData?.altText;

	const enterAnimation = (btn) => {
		gsap.to(btn, { opacity: 1, duration: 0.5 });
	};

	const exitAnimation = (btn) => {
		gsap.to(btn, { opacity: 0, duration: 0.5 });
	};

	const cssClass = `button-${id?.toLowerCase().replace(/\s/, "-")}`;

	return (
		<div
			onMouseEnter={() => {
				enterAnimation(`.${cssClass}`);
			}}
			onMouseLeave={() => exitAnimation(`.${cssClass}`)}
			className="w-100 h-100 position-relative "
		>
			<GatsbyImage
				image={image}
				className="w-100 h-100 object-fit-cover bg-primary bg-opacity-10 "
				alt={imageAlt}
			/>
			<div
				className={`animated-image-card position-absolute  bottom-0 p-xl-5 p-3 start-0 w-100  ${cssClass}`}
			>
				<div style={{ background: "rgba(8, 69, 141, 0.62)" }} className=" p-4">
					<h2 className="text-white fs-3">{name}</h2>
					<p className="text-white pb-0 mb-2">{title}</p>
					<a
						href="tel:01323 385 385"
						className={` text-white pb-0 mb-2 d-flex  align-items-center  me-lg-4`}
					>
						<FaPhone style={{ fontSize: "90%" }} className="m-0 me-2 " />
						01323 385 385
					</a>
					<a
						href="mailto:info@yourdesk.co.uk"
						className={` text-white d-flex align-items-center  me-lg-4`}
					>
						<FiMail style={{ fontSize: "90%" }} className="m-0 me-2 " />
						info@yourdesk.co.uk
					</a>
				</div>
			</div>
		</div>
	);
};

export default TeamCard;
