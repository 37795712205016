import React, { useEffect, useRef, useState } from "react";
import {
	Button,
	Col,
	Container,
	Dropdown,
	DropdownButton,
	Form,
	Row,
	Spinner,
} from "react-bootstrap";
import InputField from "./inputField";
import { BiCurrentLocation } from "react-icons/bi";
import { IoIosPeople } from "react-icons/io";
import { BsCurrencyPound } from "react-icons/bs";
import AccordianDropdown from "./a-dropdown";
import { FaBuilding } from "react-icons/fa";
import CartContext from "../context/CartContext";
import { useGeolocated } from "react-geolocated";
import { Link, navigate } from "gatsby";
import styled from "styled-components";
import AccordianDropdownSlider from "./a-dropdown-slider";
import HeroFilterTypedInput from "./hero-filter-input";
import { MdPlayArrow } from "react-icons/md";
import ChatBox from "./chatbox";

const HeroFilters = ({ extendedCallback }) => {
	const {
		cart,
		settingVal,
		selectedOfficeType,
		selectedFeatures,
		viewport,

		budgetMinMax,
		onLocationChange,
		numberOfPeople,
		budgetPerDesk,
		options,
		filteredOffices,
		newOptions,
		// budgetMinMax,
		// capacityMinMax,
	} = React.useContext(CartContext);

	//   const setCapacityHelper = (numPeople) => {
	//     return {
	//       min:
	//         numPeople?.min > 0
	//           ? numPeople?.min
	//           : capacityMinMax.minPropertyCapacity,
	//       max:
	//         numPeople?.max > 0
	//           ? numPeople?.max
	//           : capacityMinMax.maxPropertyCapacity,
	//     };
	//   };

	const [capacityMin, setCapacityMin] = useState(numberOfPeople?.min);
	const [turnArrow, setTurnArrow] = useState(false);
	const [typesTurnArrow, setTypesTurnArrow] = useState(false);
	const [showInput, setShowInput] = useState(true);

	//   const setBudgetHelper = (budget) => {
	//     return {
	//       min: budget?.min > 0 ? budget?.min : budgetMinMax?.minPropertyBudget,
	//       max: budget?.max > 0 ? budget?.max : budgetMinMax?.maxPropertyBudget,
	//       perDesk: budget?.perDesk,
	//     };
	//   };

	//   const [budgetLimits, setBudgetLimits] = useState({});
	//   console.log("budgetLimits", budgetLimits, "budgetMinMax", budgetMinMax);
	//   const [perDesk, setTotal] = useState(budgetPerDesk.perDesk);
	const [budgetMax, setBudgetMax] = useState(budgetPerDesk?.max);
	const [budgetMaxPerDesk, setBudgetMaxPerDesk] = useState(
		budgetPerDesk?.maxPerDesk
	);

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: true,
			},
			isOptimisticGeolocationEnabled: false,
			userDecisionTimeout: 5000,
		});

	const OnLocationChangeGeo = () => {
		if (isGeolocationAvailable && isGeolocationEnabled && coords) {
			onLocationChange(coords.latitude, coords.longitude);
			settingVal("Your location", "locationValue");
		} else {
			alert("Please allow access to your location on your browser");
		}
	};

	const typeItems = ["Any", "Serviced Office", "Managed Office"];

	const [showMore, setShowMore] = useState(false);
	const [type, setType] = useState(selectedOfficeType || "Any");

	const [showSpinner, setShowSpinner] = useState(false);

	const [radiosVisible, setRadiosVisible] = useState(false);
	const [radiosVisibleMore, setRadiosVisibleMore] = useState(false);

	const [typesVisible, setTypesVisible] = useState(false);
	const [turnArrowNew, setTurnArrowNew] = useState(false);
	const [typesVisibleMore, setTypesVisibleMore] = useState(false);

	const [perDesk, setPerDesk] = useState(budgetPerDesk?.perDesk);
	const radioSetter = () => {
		setPerDesk(!perDesk);
	};

	//   useEffect(() => {
	//     setBudgetLimits(budgetMinMax);
	//   }, [budgetLimits]);

	//   useEffect(() => {
	//     console.log("activated");
	//     setBudgetLimits(budgetMinMax);
	//   }, [perDesk]);

	const handleSearchClick = () => {
		const newBudget = {
			min: 0,
			max: budgetMax || budgetMinMax.max,
			maxPerDesk: budgetMaxPerDesk || budgetMinMax.maxPerDesk,
			minPerDesk: 0,
			perDesk: perDesk,
		};

		// settingVal(newBudget, "budgetPerDesk");
		// const newCapacity = { ...numberOfPeople, min: capacityMin };
		// const newCapacity = { ...numberOfPeople, min: capacityMin || 1 };

		// settingVal(newCapacity, "numberOfPeople");
		// settingVal(type, "selectedOffice");

		setShowSpinner(true);
	};

	const radiosRef = useRef(null);
	const poundSignRef = useRef(null);
	const poundSignRefMore = useRef(null);
	const radiosRefMore = useRef(null);
	const typesRef = useRef(null);
	const typesExpanderRef = useRef(null);

	useEffect(() => {}, [budgetMax, capacityMin]);

	useEffect(() => {
		/**
		 * Alert if clicked on outside of element
		 */
		function handleClickOutsideMore(event) {
			if (
				poundSignRefMore.current &&
				!poundSignRefMore.current.contains(event.target) &&
				radiosRefMore.current &&
				!radiosRefMore.current.contains(event.target)
			) {
				// onClickOutside && onClickOutside();
				// console.log("some text");
				setRadiosVisibleMore(false);
				setTurnArrow(false);
			}
		}
		function handleClickOutside(event) {
			if (
				poundSignRef.current &&
				!poundSignRef.current.contains(event.target) &&
				radiosRef.current &&
				!radiosRef.current.contains(event.target)
			) {
				// onClickOutside && onClickOutside();
				// console.log("some text");
				setRadiosVisible(false);
				setTurnArrow(false);
			}
		}
		const handleClicksOutside = (e) => {
			handleClickOutside(e);
			handleClickOutsideMore(e);
		};

		const turnArrowNew = (e) => {};

		// Bind the event listener
		document.addEventListener("mousedown", handleClicksOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<section className="position-relative ">
			<div
				style={{ zIndex: 0 }}
				className="position-relative pe-4 pe-lg-0 d-flex 
				 w-100 "
			>
				<Container className="position-relative">
					<Row className="align-items-center py-2">
						<Col xl={8}>
							<h1 className="text-white  display-3 pt-3 pb-lg-2 pt-lg-3 pb-5">
								Let YourDesk be your guide to London commercial property
							</h1>
							{/* <p style={{ fontSize: "1.2rem" }} className="text-white">
                Find your perfect office solution with the UK's most trusted
                brokerage.
              </p> */}
						</Col>
					</Row>

					<Row
						className="position-absolute w-100 d-flex justify-content-center gx-0 align-items-center"
						// style={{ height: "70px" }}
					>
						<Col xs={12}>
							<div className="d-flex justify-content-center mt-2">
								<Button
									variant="secondary"
									size="sm"
									className="me-2"
									onClick={() => setShowInput(true)}
								>
									Input
								</Button>
								<Button
									variant="secondary"
									size="sm"
									onClick={() => setShowInput(false)}
								>
									Chat
								</Button>
							</div>
						</Col>
						{showInput && (
							<Col lg={8} xl={6}>
								<div
									className=" w-100 bg-white p-2 pe-4"
									style={{
										height: "70px",
										borderTopLeftRadius: "5px",
										borderBottomLeftRadius: "5px",
									}}
								>
									<Row className="w-100 h-100 mx-0 mx-sm-2 align-items-center">
										<Col xs={11}>
											<InputField />
										</Col>
										<Col
											className="px-0 px-sm-2 text-center  text-sm-end text-lg-center"
											xs={1}
										>
											{" "}
											<a
												className="align-self-center"
												style={{ cursor: "pointer" }}
												onClick={() => OnLocationChangeGeo()}
											>
												<BiCurrentLocation className="fs-3 d-none d-sm-block primary-link" />
												<BiCurrentLocation className="fs-5 d-sm-none primary-link" />
											</a>
										</Col>
									</Row>
								</div>
							</Col>
						)}
						{!showInput && (
							<Col lg={12}>
								<ChatBox search />
							</Col>
						)}

						{showInput && (
							<Col xl={2} lg={4}>
								<Button
									onClick={() => handleSearchClick()}
									as={Link}
									to="/properties"
									className="btn btn-primary Oswald-Regular w-xl-80 text-white text-center fs-4  d-inline-block w-100 px-4 mb-2 mt-1 mt-xl-2 mb-0"
									style={{ height: "70px", borderRadius: "0px" }}
								>
									{showSpinner === false && (
										<div className="h-100 w-100  d-flex align-items-center justify-content-center">
											Search
										</div>
									)}
									{showSpinner === true && (
										<div className="h-100 w-100 d-flex align-items-center justify-content-center">
											<Spinner animation="border" variant="white" />
										</div>
									)}
								</Button>
							</Col>
						)}
					</Row>
				</Container>
			</div>
		</section>
	);
};

export default HeroFilters;

const OptionWrapper = styled.div`
	/* background: #00a8a8ad; */
	margin-top: 18px;
	border-radius: 10px;
	border: 1px black solid;
	background: #ffffffad !important;
	position: absolute;
	top: 100%;
	width: 90%;
	padding: 0px 10px 0px 10px;
	margin: 20px 10px 10px 10px;
	z-index: 2;
`;

const Option = styled.p`
	cursor: pointer;
	max-width: 100%;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	z-index: 2;
`;
