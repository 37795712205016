import { Link, useStaticQuery, graphql } from "gatsby";
import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { GatsbySeo } from "gatsby-plugin-next-seo";
// import LocalBusinessSchema from "../components/schema/localBusinessSchema";
import { Helmet } from "react-helmet";
import "mapbox-gl/dist/mapbox-gl.css";

import { FiHeart, FiCalendar } from "react-icons/fi";

import { BsPeople } from "react-icons/bs";
import { useGeolocated } from "react-geolocated";

import CartContext from "../context/CartContext";
import Team from "../components/team";
import Testimonials from "../components/testimonials";

import ContactFormHome from "../components/contact-form-home";
import AnimatedImageCard from "../components/animated-image-card";
import PreferToTalk from "../components/prefer-to-talk";
import ListingsPropertyCard from "../components/Listings-property-card";
import FeaturedProperties from "../components/featured-properties";
import HeroFilters from "../components/hero-filters";
import numberOne from "../images/fa6-solid_1.svg";
import numberTwo from "../images/fa6-solid_2.svg";
import numberThree from "../images/fa6-solid_3.svg";
import googleLogo from "../images/google.svg";
import TeamCard from "../components/team-card";
import { Button } from "react-bootstrap";
import VerticalListingsPropertyCard from "../components/vertical-listings-property-card";
import ChatBox from "../components/chatbox";

const IndexPage = () => {
	const tl = useRef();
	const data = useStaticQuery(graphql`
		query {
			allAirtableProperties(filter: { isFloor: { nin: 1 } }) {
				edges {
					node {
						addressLineOne
						desksFrom
						desksTo
						subwayStation1
						subwayStation1DistanceKm
						subwayStation1DurationMins
						subwayStation2
						subwayStation2DistanceKm
						subwayStation2DurationMins
						subwayStation3
						subwayStation3DistanceKm
						subwayStation3DurationMins
						type
						rentPM
						airtableId
						locationLatitude
						locationLongitude
						name
						provider

						features
						description

						photosNew {
							childImageSharp {
								gatsbyImageData(
									quality: 30
									placeholder: BLURRED
									formats: AUTO
								)
							}
						}
					}
				}
			}
			# wpFeaturedOffice {
			# 	featuredOfficeFields {
			# 		firstFeaturedOffice
			# 		fourthFeaturedOffice
			# 		secondFeaturedOffice
			# 		thirdFeaturedOffice
			# 	}
			# }
			site {
				siteMetadata {
					siteUrl
				}
			}
			paddington: wpMediaItem(title: { eq: "city-of-london" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			canaryWharf: wpMediaItem(title: { eq: "canary-wharf-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			piccadilly: wpMediaItem(title: { eq: "westminster" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			westminster: wpMediaItem(title: { eq: "london-bridge" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			# gherkin: wpMediaItem(title: { eq: "gherkin-placeholder" }) {
			# 	altText
			# 	localFile {
			# 		childImageSharp {
			# 			gatsbyImageData(
			# 				formats: [AUTO, WEBP]
			# 				quality: 30
			# 				transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			# 				layout: CONSTRAINED
			# 				placeholder: BLURRED
			# 			)
			# 		}
			# 	}
			# }
			heroInteriorNew: wpMediaItem(title: { eq: "Hero" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
				}
			}
			img2: wpMediaItem(title: { eq: "canary-wharf" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
			}
			team1: wpMediaItem(title: { eq: "Person-1" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
			}
			team2: wpMediaItem(title: { eq: "Person-2" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
			}
			team3: wpMediaItem(title: { eq: "Person-3" }) {
				altText
				localFile {
					childImageSharp {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							quality: 100
							transformOptions: { cropFocus: CENTER, fit: CONTAIN }
							layout: FULL_WIDTH
							placeholder: BLURRED
						)
					}
				}
			}

			# heroInterior: wpMediaItem(title: { eq: "hero-interior" }) {
			# 	altText
			# 	localFile {
			# 		publicURL
			# 		childImageSharp {
			# 			original {
			# 				height
			# 				width
			# 			}
			# 			gatsbyImageData(
			# 				formats: [AUTO, WEBP]
			# 				quality: 30
			# 				transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			# 				layout: CONSTRAINED
			# 				placeholder: BLURRED
			# 			)
			# 		}
			# 	}
			# }
			# heroWindow: wpMediaItem(title: { eq: "hero-window" }) {
			# 	altText
			# 	localFile {
			# 		publicURL
			# 		childImageSharp {
			# 			gatsbyImageData(
			# 				formats: [AUTO, WEBP]
			# 				quality: 30
			# 				transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			# 				layout: CONSTRAINED
			# 				placeholder: BLURRED
			# 			)
			# 		}
			# 	}
			# }
			# heroExterior: wpMediaItem(title: { eq: "hero-exterior" }) {
			# 	altText
			# 	localFile {
			# 		childImageSharp {
			# 			gatsbyImageData(
			# 				formats: [AUTO, WEBP]
			# 				quality: 30
			# 				transformOptions: { cropFocus: CENTER, fit: CONTAIN }
			# 				layout: CONSTRAINED
			# 				placeholder: BLURRED
			# 			)
			# 		}
			# 	}
			# }
		}
	`);

	const {
		cart,
		settingVal,
		selectedOfficeType,
		selectedFeatures,
		viewport,
		onLocationChange,
		numberOfPeople,
		budgetPerDesk,
		options,
		filteredOffices,
		newOptions,
		capacityMinMax,
		budgetMinMax,
	} = React.useContext(CartContext);

	const paddington = data.paddington;
	const canaryWharf = data.canaryWharf;
	const piccadilly = data.piccadilly;
	const westminster = data.westminster;
	// const gherkin = data.gherkin?.localFile.childImageSharp.gatsbyImageData;
	const heroInteriorNew =
		data.heroInteriorNew?.localFile.childImageSharp.gatsbyImageData;
	// const heroInterior =
	// 	data.heroInterior?.localFile.childImageSharp.gatsbyImageData;
	// const heroWindow = data.heroWindow?.localFile.childImageSharp.gatsbyImageData;
	// const heroExterior =
	// 	data.heroExterior?.localFile.childImageSharp.gatsbyImageData;

	// const featuredProperty1 = data.allAirtableProperties.edges?.filter(
	// 	(prop) =>
	// 		prop.node.name ===
	// 		data.wpFeaturedOffice.featuredOfficeFields.firstFeaturedOffice
	// );
	// const featuredProperty2 = data.allAirtableProperties.edges?.filter(
	// 	(prop) =>
	// 		prop.node.name ===
	// 		data.wpFeaturedOffice.featuredOfficeFields.secondFeaturedOffice
	// );
	// const featuredProperty3 = data.allAirtableProperties.edges?.filter(
	// 	(prop) =>
	// 		prop.node.name ===
	// 		data.wpFeaturedOffice.featuredOfficeFields.thirdFeaturedOffice
	// );
	// const featuredProperty4 = data.allAirtableProperties.edges?.filter(
	// 	(prop) =>
	// 		prop.node.name ===
	// 		data.wpFeaturedOffice.featuredOfficeFields.fourthFeaturedOffice
	// );

	const [checked, setChecked] = useState(false);

	//   const [image1, setImage1] = useState(
	//     data.heroInterior?.localFile.childImageSharp.gatsbyImageData
	//   );
	//   const [image2, setImage2] = useState(
	//     data.heroWindow?.localFile.childImageSharp.gatsbyImageData
	//   );
	//   const [image3, setImage3] = useState(
	//     data.heroExterior?.localFile.childImageSharp.gatsbyImageData
	//   );
	//   const [image1, setImage1] = useState(
	//     data.allAirtableProperties.edges[0].node.photosNew[0].childImageSharp
	//       ?.gatsbyImageData
	//   );
	//   const [image2, setImage2] = useState(
	//     data.allAirtableProperties.edges[1].node.photosNew[0].childImageSharp
	//       ?.gatsbyImageData
	//   );
	//   const [image3, setImage3] = useState(
	//     data.allAirtableProperties.edges[2].node.photosNew[0].childImageSharp
	//       ?.gatsbyImageData
	//   );

	const addGradient = (image) => {
		const pluginImage = getImage(image);

		// Watch out for CSS's stacking order, especially when styling the individual
		// positions! The lowermost image comes last!
		return [pluginImage];
	};

	const heroInteriorGradient = getImage(heroInteriorNew);
	// const heroWindowGradient = getImage(heroWindow);
	// const heroExteriorGradient = getImage(heroExterior);

	const siteUrl = data.site.siteMetadata.siteUrl;

	const [activePopup, setActivePopup] = useState(-1);
	const [showPopup, setShowPopup] = useState(false);
	const [allowLocation, setAllowLocation] = useState(false);

	const [capacity, setCapacity] = useState(
		numberOfPeople > 0 ? numberOfPeople : "Capacity"
	);
	const [budget, setBudget] = useState(
		budgetPerDesk > 0 ? budgetPerDesk : "Budget"
	);

	const bgImageRef = useRef(null);
	const [filtersExtendedClass, setFiltersExtendedClass] = useState(false);

	const onShowMoreClick = (e) => {
		bgImageRef.current.base.classList.remove("bg-hero-height");
		bgImageRef.current.base.classList.add("bg-hero-height-extended");
	};

	const { coords, isGeolocationAvailable, isGeolocationEnabled } =
		useGeolocated({
			positionOptions: {
				enableHighAccuracy: true,
			},
			isOptimisticGeolocationEnabled: false,
			userDecisionTimeout: 5000,
		});

	const OnLocationChangeGeo = () => {
		if (isGeolocationAvailable && isGeolocationEnabled && coords) {
			onLocationChange(coords.latitude, coords.longitude);
			settingVal("Your location", "locationValue");
		} else {
			alert("Please allow access to your location on your browser");
		}
	};
	const OnLocationLinkClick = (name, lat, long) => {
		settingVal(name, "locationValue");
		onLocationChange(lat, long);
	};
	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
		],
	};
	function getRandomInt(max) {
		return Math.floor(Math.random() * max);
	}
	const addressName = "My Business, 123 Main Street, Vancouver, BC, Canada";
	const provinces = addressName?.split(",").slice(0, 2).join(",");

	const mapRef = useRef();
	const typeItems = ["Any", "Serviced Office", "Managed Office"];

	useEffect(() => {
		onLocationChange(viewport.latitude, viewport.longitude);
	}, []);

	//   useEffect(() => {
	//     // var length1 = getRandomInt(
	//     //   data.allAirtableProperties.edges[0].node.photosNew?.length
	//     // );
	//     // var length2 = getRandomInt(
	//     //   data.allAirtableProperties.edges[1].node.photosNew?.length
	//     // );
	//     // var length3 = getRandomInt(
	//     //   data.allAirtableProperties.edges[2].node.photosNew?.length
	//     // );

	//     setImage1(
	//       addGradient(data.heroInterior?.localFile.childImageSharp.gatsbyImageData)
	//     );
	//     setImage2(
	//       addGradient(data.heroWindow?.localFile.childImageSharp.gatsbyImageData)
	//     );
	//     setImage3(
	//       addGradient(data.heroExterior?.localFile.childImageSharp.gatsbyImageData)
	//     );
	//   }, []);

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>

			<GatsbySeo
				title="Home | Your Desk Workplace Solutions"
				description="Find your ideal office with Your Desk Workplace Solutions."
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}`,
					title: "Home | Your Desk Workplace Solutions",
					description:
						"Find your ideal office with Your Desk Workplace Solutions",
					images: [
						{
							url: `${data.heroInterior?.localFile.publicURL}`,
							width: `${data.heroInterior?.localFile.childImageSharp.original.width}`,
							height: `${data.heroInterior?.localFile.childImageSharp.original.height}`,
							alt: `${data.heroInterior?.altText}`,
						},
					],
				}}
			/>

			<div>
				<Layout homepage={true}>
					<div
						style={{ overflow: "hidden" }}
						className={
							filtersExtendedClass
								? "bg-hero-height-hidden-extended"
								: "bg-hero-height-hidden"
						}
					>
						<Container fluid className="px-0 mx-0 position-absolute top-0">
							<Row style={{ zIndex: 999999 }} className="px-0 g-0 mx-0 ">
								<Col xl={12}>
									<div
										style={{ overflow: "hidden" }}
										className={`w-100 position-relative ${
											filtersExtendedClass
												? "bg-hero-height-extended"
												: "bg-hero-height"
										}`}
									>
										{/* <div
											style={{ zIndex: -1, opacity: 0.55 }}
											className="bg-black position-absolute start-0 top-0 w-100 h-100"
										></div> test*/}
										<BgImage
											style={{
												height: "56rem",
												maxHeight: "56rem",
												zIndex: 0,
											}}
											ref={bgImageRef}
											className={`w-100`}
											// image={getImage(image1)}
											image={heroInteriorGradient}
										></BgImage>
									</div>
								</Col>
							</Row>
						</Container>
						<HeroFilters extendedCallback={setFiltersExtendedClass} />
					</div>

					<section className="pb-5">
						<Container>
							<Row className=" pt-8 pt-xl-5 ">
								<Col md={12} lg={12} className=" pb-lg-0  px-4 pt-3 pt-md-0">
									{/* <ChatBox /> */}
									<h2 className=" mt-5 mb-4 fs-1">
										What YourDesk can do for you
									</h2>
									<p>
										At <strong>YourDesk</strong>, we are firm believers in the
										profound potential of flexibility. We recognise that no two
										professionals are the same, and each one possesses a
										distinct work style that deserves a workspace tailored to
										their needs. It's in this spirit that we have meticulously
										crafted our flexible office solutions, with the sole aim of{" "}
										<strong>empowering you</strong> to be your most productive
										and creative self.
									</p>
									<p>
										Our approach centres on the concept of{" "}
										<strong>adaptability</strong>. We understand that your
										requirements today may not be the same tomorrow, and our
										dynamic workspaces are primed to evolve alongside your
										ever-changing needs. Whether you need a tranquil private
										office for focused work, a collaborative co-working space
										for brainstorming sessions, or the flexibility of a virtual
										office, we've got you covered.
									</p>
									<p>
										At YourDesk, we're not just providing office spaces; we're
										offering you a platform to unlock your full potential. We
										encourage you to break free from the confines of traditional
										office setups and embrace the freedom to shape your
										workspace in a manner that perfectly aligns with your{" "}
										<strong>ambitions</strong> and <strong>work style</strong>.
									</p>
								</Col>
							</Row>
							<Row>
								<Col className="text-center">
									<Button
										className="btn btn-darker-blue text-white w-100 w-md-auto py-3 mt-4 px-5 "
										as={Link}
										to="/contact-us"
									>
										Get in touch
									</Button>
								</Col>
							</Row>
						</Container>
					</section>

					<section className="py-5 pt-lg-7">
						<Container className="px-4 px-md-0">
							<Row className="mb-6 gx-lg-5 d-flex align-items-center ">
								<Col
									lg={{ span: 5, order: "last" }}
									className="ps-lg-3 d-md-none d-lg-block mb-6 mb-lg-0"
								>
									<GatsbyImage
										image={data.img2?.localFile.childImageSharp.gatsbyImageData}
										alt={data.img2?.altText}
										className="w-100  "
									/>
								</Col>
								<Col lg={7} className="ps-lg-6">
									<div>
										<h2 className="mb-5 fs-1 ">Our process</h2>
										<Row className="pb-5">
											<Col xs={2} md={1}>
												<img src={numberOne} alt="One" className="w-90" />{" "}
											</Col>

											<Col xs={10} md={11}>
												<h3 className="fs-4">Step 1</h3>
												<p>
													At YourDesk, the journey to your perfect flexible
													office starts with a deep understanding of your unique
													requirements. Our expert team takes the time to engage
													with you, whether through a consultation or a detailed
													questionnaire, to gather insights into your business,
													work style, location preferences, and the amenities
													that matter most to you. This foundational step
													ensures that we are aligned with your vision, setting
													the stage for the ideal flexible office search.
												</p>
											</Col>
										</Row>
										<Row className="pb-5">
											<Col xs={2} md={1}>
												<img src={numberTwo} alt="Two" className="w-90" />{" "}
											</Col>

											<Col xs={10} md={11}>
												<h3 className="fs-4">Step 2</h3>
												<p>
													Armed with a comprehensive understanding of your
													needs, our team employs its extensive network and
													market knowledge to initiate a tailored search. We
													scour the city's prime locations and carefully vet a
													selection of flexible office spaces that match your
													criteria. These spaces can range from private offices,
													co-working areas, to virtual office solutions. Our
													goal is to present you with a curated list of options
													that perfectly align with your vision and preferences.
												</p>
											</Col>
										</Row>
										<Row>
											<Col xs={2} md={1}>
												<img src={numberThree} alt="Three" className="w-90" />{" "}
											</Col>

											<Col xs={10} md={11}>
												<h3 className="fs-4">Step 3</h3>
												<p>
													Upon receiving your curated list, you have the
													opportunity to visit and assess the shortlisted spaces
													first-hand. We schedule viewings at your convenience,
													allowing you to see the spaces up close and evaluate
													their suitability. Once you've found the ideal
													flexible office that resonates with your vision, we
													facilitate the leasing process, ensuring that you step
													into your new workspace with ease. We handle all the
													necessary paperwork and logistics, providing a
													seamless transition into your perfect flexible office.
												</p>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 pb-lg-7">
						<Container>
							<Row>
								<Col>
									<h2 className="mb-5 text-center fs-1">
										Customer Testimonials
									</h2>
								</Col>
							</Row>
							<Row className="align-items-center">
								<Col md={2} className="text-center">
									<img
										src={googleLogo}
										alt="Google Logo"
										style={{ width: "40px" }}
									/>{" "}
									<h3 className="fs-4 mt-3">Nellie Harrison</h3>
								</Col>
								<Col md={10}>
									<p>
										Choosing YourDesk has been a game-changer for my business.
										Their personalized approach to finding the perfect flexible
										office space exceeded my expectations. From the initial
										consultation where they took the time to truly understand my
										needs to the meticulously curated list of spaces they
										provided, it was evident that they were committed to finding
										a solution tailored just for me. The viewing and selection
										process was seamless, and I'm now thriving in a workspace
										that aligns perfectly with my vision. YourDesk has made the
										journey to my ideal office a stress-free and rewarding
										experience. I can't recommend them enough!
									</p>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="pb-5 pb-lg-7">
						<Container>
							<Row className="pb-5 gx-5">
								<Col lg={6}>
									<h2 className="  fs-1">
										Our commercial property specialists
									</h2>
								</Col>
								<Col>
									<p>
										Our friendly team members at YourDesk are the heart and soul
										of our organization. They're not just{" "}
										<strong>experts</strong> in the field; they're also your
										dedicated partners on your journey to the perfect flexible
										office.
									</p>
									<p>
										Always <strong>approachable</strong> and eager to assist,
										our team embodies a welcoming spirit that instantly puts you
										at ease. They go the extra mile to ensure your needs are not
										only met but exceeded, making the entire process of finding
										your ideal workspace a pleasant and stress-free experience.
										Their warmth and professionalism create a sense of
										belonging, turning every interaction into a positive and
										memorable experience.{" "}
									</p>

									<p>
										At YourDesk, our team members are not just here to assist;
										they're here to build lasting <strong>relationships</strong>{" "}
										and provide <strong>exceptional</strong> service every step
										of the way.
									</p>
								</Col>
							</Row>
							<Row className="gy-4 pb-5 pb-lg-0">
								<Col md={6} lg={4}>
									<TeamCard
										id="team-1"
										name="Isabelle Walls"
										title="Owner"
										imageData={data.team1}
									/>
								</Col>
								<Col md={6} lg={4}>
									<TeamCard
										id="team-2"
										name="Isabelle Walls"
										title="Owner"
										imageData={data.team2}
									/>
								</Col>
								<Col md={6} lg={4}>
									<TeamCard
										id="team-3"
										name="Isabelle Walls"
										title="Owner"
										imageData={data.team3}
									/>
								</Col>
							</Row>
						</Container>
					</section>
					<section className="bg-primary py-lg-5  ">
						<Container className="px-4 px-lg-0">
							<Row className="g-md-5 g-3">
								<Col lg={4} className="">
									<h2 className="fs-1 text-white">Area guides</h2>
								</Col>
								<Col lg={8}>
									<AnimatedImageCard id="london" imageData={paddington} />

									<AnimatedImageCard id="canary" imageData={canaryWharf} />

									<AnimatedImageCard id="west" imageData={piccadilly} />

									<AnimatedImageCard id="bridge" imageData={westminster} />
								</Col>
							</Row>
							<div style={{ height: "3rem" }}></div>
						</Container>
					</section>
					<section className="pt-5 pt-lg-7">
						<Container>
							<Row>
								<Col className="text-center">
									<h2 className="fs-1 pb-4">
										Some of our favourite office spaces
									</h2>
								</Col>
							</Row>

							<Row>
								<FeaturedProperties
									colWidthXL={4}
									colWidthLG={6}
									CardType={VerticalListingsPropertyCard}
									properties={data.allAirtableProperties.edges?.slice(0, 3)}
								/>
							</Row>
							<Row>
								<Col className="text-end">
									<Button
										className="btn btn-darker-blue w-100 w-md-auto text-white py-3  px-5 "
										as={Link}
										to="/properties"
									>
										Search all properties
									</Button>
								</Col>
							</Row>
						</Container>
					</section>
					<section>
						<Container>
							<div className="py-3 py-md-5 px-3 px-md-5 my-lg-6 my-3 ">
								<Row>
									<Col>
										<h2 className="fs-1 pb-4">Get in touch</h2>
									</Col>
								</Row>
								<Row>
									<Col>
										<p className="pb-4">
											Fill in your contact details below and we'll put you in
											touch with one of our experts.
										</p>
									</Col>
								</Row>
								<ContactFormHome />
							</div>
						</Container>
					</section>
				</Layout>
			</div>
		</>
	);
};

export default IndexPage;
{
	/* .sort((p1, p2) =>
													getDistanceFromLatLonInKm(
														viewport.latitude,
														viewport.longitude,
														p1.node.locationLatitude,
														p1.node.locationLongitude
													) *
														0.621371 >
													getDistanceFromLatLonInKm(
														viewport.latitude,
														viewport.longitude,
														p2.node.locationLatitude,
														p2.node.locationLongitude
													) *
														0.621371
														? 1
														: getDistanceFromLatLonInKm(
																viewport.latitude,
																viewport.longitude,
																p1.node.locationLatitude,
																p1.node.locationLongitude
														  ) *
																0.621371 <
														  getDistanceFromLatLonInKm(
																viewport.latitude,
																viewport.longitude,
																p2.node.locationLatitude,
																p2.node.locationLongitude
														  ) *
																0.621371
														? -1
														: 0
												) */
}

// .sort((p1, p2) =>
// 												getDistanceFromLatLonInKm(
// 													viewport.latitude,
// 													viewport.longitude,
// 													p1.node.locationLatitude,
// 													p1.node.locationLongitude
// 												) *
// 													0.621371 >
// 												getDistanceFromLatLonInKm(
// 													viewport.latitude,
// 													viewport.longitude,
// 													p2.node.locationLatitude,
// 													p2.node.locationLongitude
// 												) *
// 													0.621371
// 													? 1
// 													: getDistanceFromLatLonInKm(
// 															viewport.latitude,
// 															viewport.longitude,
// 															p1.node.locationLatitude,
// 															p1.node.locationLongitude
// 													  ) *
// 															0.621371 <
// 													  getDistanceFromLatLonInKm(
// 															viewport.latitude,
// 															viewport.longitude,
// 															p2.node.locationLatitude,
// 															p2.node.locationLongitude
// 													  ) *
// 															0.621371
// 													? -1
// 													: 0
// 											)
