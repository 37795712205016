import React, { useEffect, useRef, useState } from "react";
import { NumericFormat } from "react-number-format";

const HeroFilterTypedInput = ({
	setter,
	setter2,
	value,
	value2,
	defaultText,
	perDesk,
	budget,
}) => {
	const [tempValue, setTempValue] = useState(defaultText);
	const [tempValue2, setTempValue2] = useState(defaultText);

	return (
		<NumericFormat
			style={{ border: "0px solid #58B9FA" }}
			className=" ssp-bold w-80 ps-2 text-white fs-5 bg-t"
			placeholder={defaultText}
			type="text"
			thousandsGroupStyle="thousand"
			thousandSeparator=","
			value={tempValue2}
			onValueChange={(e) => {
				if (budget) {
					if (!perDesk) {
						setter(e.value.replace(",", ""));
						setTempValue(e.value.replace(",", ""));
					} else {
						setter2(e.value.replace(",", ""));
						setTempValue(e.value.replace(",", ""));
					}
				} else {
					setter(e.value.replace(",", ""));
					setTempValue(e.value.replace(",", ""));
				}
			}}
			onFocus={(e) => {
				if (value === defaultText) {
					if (budget) {
						if (!perDesk) {
							setter("");
						} else {
							setter("");
						}
					} else {
						setter("");
					}
				}
			}}
			onBlur={(e) => {
				if (Number.isInteger(Number(+e.value)) && e.value !== "") {
					if (budget) {
						if (!perDesk) {
							setter(e.value?.replace(",", ""));
							setTempValue(e.value?.replace(",", ""));
						} else {
							setter2(e.value?.replace(",", ""));
							setTempValue(e.value?.replace(",", ""));
						}
					} else {
						setter(e.value?.replace(",", ""));
						setTempValue(e.value?.replace(",", ""));
					}
				} else {
					setTempValue(e.value?.replace(",", ""));
				}
			}}
			onKeyDown={(e) => {
				if (e.key === "Enter") e.target.blur();
			}}
		/>
	);
};

export default HeroFilterTypedInput;
