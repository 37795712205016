import React, { useContext, useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import AccordionContext from "react-bootstrap/AccordionContext";
import { MdPlayArrow } from "@react-icons/all-files/md/MdPlayArrow";
import CartContext from "../context/CartContext";
import styled from "styled-components";
import MultiRangeSlider from "multi-range-slider-react";
import { Form } from "react-bootstrap";

const AccordianDropdownSlider = ({
	values,
	minMaxLimits,
	items,
	valId,
	icon,
	width,
	radio,
	title,
}) => {
	const { settingVal } = React.useContext(CartContext);
	const [turnArrow, setTurnArrow] = useState(false);
	const [eKey, setEKey] = useState("1");
	const [minValue, setMinValue] = useState(values.min);
	const [maxValue, setMaxValue] = useState(values.max);
	const [perDesk, setPerDesk] = useState(values.perDesk);

	const handleInput = (e) => {
		setMinValue(e.minValue);
		setMaxValue(e.maxValue);
	};

	const radioSetter = () => {
		setPerDesk(!perDesk);
	};

	useEffect(() => {
		const valNew = { max: maxValue, min: minValue };
		if (radio) valNew.perDesk = perDesk;
		settingVal(valNew, valId);
	}, [minValue, maxValue, perDesk]);

	return (
		<div
			className={`  py-0 ${width}`}
			style={{ border: "2px solid white", cursor: "pointer" }}
		>
			<Accordion activeKey={eKey}>
				<Card className="border-0 my-auto  py-0 my-0 bg-t">
					<Card.Header style={{ height: "66px" }} className="border-0 px-0 m-0">
						<ContextAwareToggle
							setEKey={setEKey}
							turnArrow={turnArrow}
							eKey={eKey}
							setTurnArrow={setTurnArrow}
							eventKey="0"
							className=" border-0 b-none"
							icon={icon}
						>
							<div className="d-flex align-items-center ">
								<p className="pb-0 mb-0 ssp-bold text-white fs-5">
									{minValue} - {maxValue}
								</p>
							</div>
						</ContextAwareToggle>
					</Card.Header>
					<Accordion.Collapse
						style={{ backgroundColor: "rgba(255, 255, 255, 0.422)" }}
						eventKey="0"
						className=" whiteish"
					>
						<OptionWrapper className="whiteish">
							<Row>
								<Col>
									<h3 className="text-primary fs-5">{title}</h3>
								</Col>
							</Row>
							{radio && (
								<Row>
									<Col xs={6}>
										<Form.Check
											checked={!perDesk}
											onClick={radioSetter}
											type="radio"
											label="Total"
											className="pb-1"
										/>
									</Col>
									<Col xs={6}>
										<Form.Check
											checked={perDesk}
											onClick={radioSetter}
											type="radio"
											label="Per Desk"
											className="pb-1"
										/>
									</Col>
								</Row>
							)}

							<MultiRangeSlider
								className="py-4 border-0 ps-0"
								min={perDesk ? minMaxLimits.minPerDesk : minMaxLimits.min}
								max={perDesk ? minMaxLimits.maxPerDesk : minMaxLimits.max}
								step={5}
								minValue={minValue}
								maxValue={maxValue}
								ruler={false}
								barInnerColor="primary"
								onInput={(e) => {
									handleInput(e);
								}}
							/>
						</OptionWrapper>
					</Accordion.Collapse>
				</Card>
			</Accordion>
		</div>
	);
};

export default AccordianDropdownSlider;

function ContextAwareToggle({
	children,
	eventKey,
	callback,
	setTurnArrow,
	turnArrow,
	setEKey,
	eKey,
	icon,
}) {
	const currentEventKey = useContext(AccordionContext);

	const decoratedOnClick = useAccordionButton(eventKey, () => {
		if (eKey === "1") {
			setEKey("0");
		} else if (eKey === "0") {
			setEKey("1");
		}
		callback && callback(eventKey);

		setTurnArrow(!turnArrow);
	});

	const isCurrentEventKey = currentEventKey === eventKey;

	return (
		<Card.Header className="border-0" onClick={decoratedOnClick}>
			<Row className="align-items-center">
				<Col
					className="d-flex justify-content-center align-items-center ps-0"
					xs={2}
				>
					{icon}
				</Col>
				<Col
					className="d-flex justify-content-start align-items-center ps-0"
					xs={9}
				>
					{children}
				</Col>
				<Col xs={1} className="d-flex px-0  justify-content-start">
					<MdPlayArrow
						className={`position-relative fs-5 text-white  ${
							turnArrow ? "cheveron-up" : "cheveron-down "
						}`}
					/>
				</Col>
			</Row>
		</Card.Header>
	);
}

const OptionWrapper = styled.div`
	/* background: #00a8a8ad; */
	margin-top: 18px;
	border-radius: 10px;
	border: 1px black solid;
	background: #ffffffff !important;
	position: absolute;
	transform: translateX(-30%);
	top: 100%;
	width: 200%;
	padding: 10px 20px;
	margin: 20px 10px 10px 10px;
	z-index: 2;
`;

const Option = styled.p`
	cursor: pointer;
	max-width: 100%;
	margin-bottom: 0.5rem;
	margin-top: 0.5rem;
	z-index: 2;
`;
